#page-collectivites{
    color:black;
    font-family: Raleway;

    h2{
        font-family: Raleway; 
        font-weight: bold;
    }

    .btn-call-to-action {
        font-family: Raleway;
        font-weight: bold;
        color:black;
        font-size: 16px;
    }

    .colloc-bandeau-children-container {
        width: 100%;
    }

    .section-confiance {
        margin-top: 11vh;
    }
    
    .section-confiance-title {
        text-align: center;
        margin-bottom: 10px;
        width: 100%;
        color: black;
    }

    .section-confiance-btn-container{
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    .section-spacing-standard {
        margin-top: 10vh;
    }
    .section-spacing-custom {
        margin-top:150px;
    }
    .last-section-margin {
        margin-bottom: 80px;
        margin-top: 100px;
    }   

    .section-spacing-h2{
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        color: black;
    }

    .reversed-mobile-section{
        margin: 55px 0px;
     }
    .confiance-section-logo-container{
        max-width: 180px;
    }

    .avantage-section-container {
        width: 90%;
        margin: 0 auto;
    }
    .avantage-section-illustration-column{
        padding:0;
    }
    .avantage-section-illustration-container-right{
        display: flex;
        justify-content: flex-end;
    }
    .avantage-section-illustration-container-left{
        display: flex;
        justify-content: start;
    }
    .avantage-section-title {
        font-family: Raleway;
        font-weight: bold;
        font-size: 24px;
        color:black;
    }
    .avantage-section-description {
        font-family: Raleway;
        font-size: 20px;
        color: black;
    }

    .actor-section-title {
        text-align: center;
        color: #000000;
        margin-bottom: 25px;
    }
    .actor-section-img-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .actor-container-element-img-block {
        display: flex;
        justify-content: center;
    }
    .actor-image {
        max-width: 300px;
    }
    .actor-image-substitle {
        width: 100%;
        text-align: center;
        font-family: raleway;
        font-size: 24px;
        color: black;
    }

    .section-referencement-img-first-block > img{
        width:100px;
    }

   
    /* Call to action bas de page */
    .call-to-action-global-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        color: #000000;
    }
    .call-to-action-left-box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background-color: #E0E8EB;
        border-radius: 4px 0px 0px 4px;
        padding: 30px;
        text-align: left;
        width:50%;
    }
    .call-to-action-right-box {
        background-color: #005877;
        color: #ffffff;
        border-radius: 0px 4px 4px 0px;
        padding: 30px;
        width:50%;
        text-align: left; 
        font-size:18px;
    }
    .call-to-action-header {
        font-weight: bold;
        font-size: 20px;
    }
    .call-to-action-subtitle {
        font-size: 16px;
    }
 
    .call-to-action-btn-first {
        font-size:17px ;
        margin-right: 30px ;
        font-family: raleway-bold ;
    }
    .call-to-action-btn-second {
        font-size:17px ;
        margin-right: 30px ;
        font-family: raleway-bold ;
        background: transparent;
        border:2px solid #005877 ;
        color: #005877 
    }
    .call-to-action-btn-container {
        display: flex;
        flex-wrap: wrap;
    }
    .call-to-action-advantage-container{
        display:flex;
        align-items: baseline;
    }
    .call-to-action-advantage-container-margin{
        margin-bottom: 12px;
    }
    .call-to-action-advantage-icon {
        color: #ffffff
    }
    .call-to-action-advantage-text{
        margin-left: 9px;
        font-size: 16px;
    }
}

@media all and (max-width: 768px) {
    #page-collectivites{
        .column-image-control-mobile{
            max-width:60px
        }
        .section-confiance-title {
            text-align: start;
        }

        .reversed-mobile-section{
            flex-direction: column-reverse;
        }
        .avantage-section-container {
            width: 100%;
        }
        .avantage-section-illustration-container-right,.avantage-section-illustration-container-left{
            justify-content: center;
        }
        .avantage-section-title {
            font-size: 20px;
        }
        .avantage-section-description {
            font-family: Raleway;
            font-size: 16px;
        }

        .actor-section-title {
            text-align: start;
        }
        .actor-container-element {
            margin-top: 40px;
        }
        .actor-section-img-container {
            display: flex;
            flex-direction: column;
        }
        .section-spacing-h2{
            margin-bottom: 25px;
            text-align: start;
        }

        .section-referencement-img-first-block, .section-referencement-img-last-block {
            display: flex;
            justify-content: center;
        }
    }
}

@media all and (max-width: 992px) {
    #page-collectivites{
        .reversed-mobile-section{
            margin-bottom: 40px !important;
        }
        .actor-image-substitle {
            font-size: 20px;
            margin-top: 1vh;
        }
        .call-to-action-left-box {
            border-radius: 4px 4px 0px 0px;
            width:100%;
        }
        .call-to-action-right-box {
            border-radius: 0px 0px 4px 4px ;
            width:100%;
        }
        .call-to-action-btn-first{
            margin-bottom: 14px !important;
        }
        .section-spacing-custom {
            margin-top:11vh;
        }
    }
}

@media all and (min-width:768px) {
    #page-collectivites{
        .actor-image {
            max-width: 250px;
        }
        .actor-image-substitle {
            max-width: 300px;
        }
        .section-confiance-btn-container{
            margin-top: 40px;
        }
        .section-referencement-img-last-block {
            display: flex;
            justify-content: flex-end;
        }
        .call-to-action-global-container {
            width: 90%;
        }
    }
}
@media all and (min-width:992px) {
    #page-collectivites{
        .actor-image {
            max-width: 300px;
        }
        .actor-image-substitle {
            max-width: inherit;
        }
  
    }
}

@media all and (min-width:1200px) {
        .avantage-section-title {
            margin-bottom: 25px;
        }
}