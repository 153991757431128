.virement-resume{
    @media all and (min-width: 767px){
        min-width: 350px;
    }
}
.virement-resume-row {
    padding-top: 0px !important;
}
.virement-resume-column{
    padding-left: 0.1rem !important;
    padding-right:  0.1rem !important;
}

.flex-column{
    display: flex;
    flex-direction: column;
}

.image-icon{
    margin-right: 10px;
    min-width: 24px;
}

.align-center{
    align-items: center;
}

.resume-info-container{
    display: flex;
    margin-top: 15px;
}

.resume-info-paragraph{
    margin-bottom: 2px;
    margin-right: 12px;
}

